import React from 'react';

import homeImage from '@images/paymongo-payments.png';

import '@style/base.scss';
import '@style/grid.scss';
import './HeroSection.scss';
import { Button, PageSection } from '@components';

const HeroSection = () => (
  <PageSection className="section-hero">
    <div className="columns">
      <div className="hero-image-container column text-left-is-desktop">
        <img className="hero-image" alt="placeholder" src={homeImage} />
      </div>
      <div className="hero-content column text-left-from-tablet">
        <h1>
          <span className="text-3xl-is-mobile text-3xl-is-tablet text-ultra">Get paid online</span>
          <br />
          <span className="text-gr500 text-3xl-is-mobile text-3xl-is-tablet text-medium text-ultra font-weight-semibold">
            without the hassle
          </span>
        </h1>
        <p className="text-lg-is-mobile text-xl-is-tablet text-xl-is-desktop">
          PayMongo makes it easy for you to run an online business. We help you get paid by your customers, any time and anywhere.
        </p>
        <Button
          className="button-cta display-block"
          buttonLabel="Get started for free"
          destination={process.env.GATSBY_LINK_TO_SIGNUP}
        />
      </div>
    </div>
  </PageSection>
);

export default HeroSection;
