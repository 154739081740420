import React from 'react';

import { Button, PageSection } from '@components';
import { Link } from 'gatsby';

import bgImage from '@images/business-size-bg.png';
import lgBusiness from '@images/business-lg.png';
import mdBusiness from '@images/business-md.png';
import smBusiness from '@images/business-sm.png';
import BusinessPricingItem from './components/BusinessPricingItem';

import '@style/base.scss';
import '@style/grid.scss';
import './BusinessPricing.scss';

const items = [
  {
    alt: 'Freelancers and online sellers',
    description: 'Freelancers and online sellers',
    size: 'sm',
    src: smBusiness,
    textSize: 'text-2xs'
  },
  {
    alt: 'Small to medium enterprise',
    description: 'Small to medium enterprises',
    size: 'md',
    src: mdBusiness,
    textSize: 'text-2xs'
  },
  {
    alt: 'Large corporations',
    description: 'Large corporations',
    size: 'lg',
    src: lgBusiness,
    textSize: 'text-2xs'
  }
];

const BusinessPricing = () => {
  const businessPricingItems = items.map((item, index) => {
    const key = item.description + index;

    return (
      <BusinessPricingItem
        alt={item.alt}
        className="column"
        description={item.description}
        key={key}
        size={item.size}
        src={item.src}
        textSize={item.textSize}
      />
    );
  });

  return (
    <PageSection className="section-business-pricing gradient -larger">
      <div className="columns is-mobile is-col wrapper">
        <img alt="bg" src={bgImage} className="bg-image -mobile" />
        <div className="column section-text">
          <h2 className="text-2xl-is-mobile text-3xl-from-tablet text-ultra text-gr800">
            PayMongo supports businesses of all sizes
          </h2>
          <p className="text-lg text-gr800 mb-none-from-tablet">
            Whether you are starting up or venturing to larger markets, we have got you covered.
          </p>
          <div className="business-items columns is-mobile display-none-from-tablet">
            {businessPricingItems}
          </div>
        </div>
        <div className="column">
          <Button
            className="button-cta display-block"
            buttonLabel="Get started for free"
            destination={process.env.GATSBY_LINK_TO_SIGNUP}
          />
          <Link
            className="link width-full-from-tablet text-center-from-tablet display-inline-block"
            to="/pricing"
          >
            Learn more about our pricing
          </Link>
        </div>
        <div className="business-items columns is-mobile display-none-is-mobile ">
          {businessPricingItems}
        </div>
        <img alt="bg" src={bgImage} className="bg-image -tablet" />
      </div>
    </PageSection>
  );
};

export default BusinessPricing;
