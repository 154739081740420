import React from 'react';
import { string } from 'prop-types';

import './TestimonialListItem.scss';

const TestimonialListItem = ({ image, user, company, message, position }) => (
  <li className="column testimonial-list-item">
    <div className="columns is-centered testimonial-list-item-content">
      <div className="column">
        <p className="text-sh800 mb-none text-xs text-left">{message}</p>
      </div>
      <div className="column testimonial-source">
        <div className="testimonial-list-content mt-none-from-tablet">
          <img src={image} alt="placeholder" className="profile-picture" />
          <div>
            <p className="text-sh900 my-none">{user}</p>
            <p className="text-sh800 text-2xs my-none">{position}</p>
            <p className="text-sh700 text-2xs my-none">{company}</p>
          </div>
        </div>
      </div>
    </div>
  </li>
);

TestimonialListItem.propTypes = {
  image: string.isRequired,
  user: string.isRequired,
  company: string.isRequired,
  message: string.isRequired,
  position: string.isRequired,
};

export default TestimonialListItem;
