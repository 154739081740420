import React from 'react';

import secured from '@images/icon-secure.png';
import seamless from '@images/icon-integrate.png';
import noProb from '@images/icon-no-prob.png';
import zeroFee from '@images/icon-zero-fee.png';

import { Button, PageSection } from '@components';

import SellingPointListItem from './components/selling_point_list_item/SellingPointListItem';

import '@style/base.scss';
import '@style/grid.scss';
import './SellingPointsSection.scss';

const listData = [
  {
    image: zeroFee,
    heading: 'Zero setup or monthly fees',
    subtext: 'Pay only when transactions go through.'
  },
  {
    image: noProb,
    heading: 'No website? No problem!',
    subtext: 'Use PayMongo Links and get paid via chat, PMs and SMS.'
  },
  {
    image: secured,
    heading: 'Secured transactions for you and your customers',
    subtext:
      'Our advanced fraud detection engines protect you from bogus buyers and fraudulent transactions.'
  },
  {
    image: seamless,
    heading: 'Seamless integration with your website or app',
    subtext: 'Use the PayMongo API to power payment experiences that delight your customers.'
  }
];

const SellingPointsSection = () => {
  const sellingPointListItems = listData.map((item, index) => (
    <SellingPointListItem
      heading={item.heading}
      image={item.image}
      key={`selling-point-item-${index + 1}`}
      subtext={item.subtext}
    />
  ));

  return (
    <PageSection id="features" className="section-selling-points section-padding-sm">
      <div className="columns is-desktop">
        <div className="selling-points-heading column is-one-thirds-desktop">
          <h2 className="text-2xl-is-mobile text-3xl-is-tablet text-ultra text-left-from-tablet mb-md-is-desktop">
            Grow with PayMongo
          </h2>
          <p className="text-lg text-xl-from-tablet">
            Here are some great reasons why you should choose us.
          </p>
        </div>
        <div className="selling-points-list-container column is-two-thirds-desktop">
          <ul className="selling-points-list">{sellingPointListItems}</ul>
          <Button
            className="button-cta display-block"
            buttonLabel="Get started for free"
            destination={process.env.GATSBY_LINK_TO_SIGNUP}
          />
        </div>
      </div>
    </PageSection>
  );
};

export default SellingPointsSection;
