import React from 'react';

import { BaseTemplate } from '@templates';
import { SEO } from '@components';

import HeroSection from './sections/hero_section/HeroSection';
import PartnersSection from './sections/partners_section/PartnersSection';
import BusinessPricing from './sections/business_pricing/BusinessPricing';
import SellingPointsSection from './sections/selling_points_section/SellingPointsSection';
import TestimonialsSection from './sections/testimonials_section/TestimonialsSection';

import '@style/base.scss';
import '@style/grid.scss';

const IndexPage = () => (
  <BaseTemplate>
    <SEO title="Simplifying payments for the modern business" />
    <HeroSection />
    <BusinessPricing />
    <SellingPointsSection />
    <PartnersSection />
    <TestimonialsSection />
  </BaseTemplate>
);

export default IndexPage;
