import React from 'react';

import { PageSection } from '@components';

import dmci from '@images/merchant-dmci.png';
import kumu from '@images/merchant-kumu.png';
import pdax from '@images/merchant-pdax.png';
import goodwork from '@images/merchant-goodwork.png';
import wildflour from '@images/merchant-wildflour.png';
import lugang from '@images/merchant-lugang.png';

import './PartnersSection.scss';

const Partners = [
  {
    name: 'PDAX',
    src: pdax,
    class: 'pdax'
  },
  {
    name: 'DMCI',
    src: dmci,
    class: 'dmci'
  },
  {
    name: 'Kumu',
    src: kumu,
    class: 'kumu'
  },
  {
    name: 'GoodWork.PH',
    src: goodwork,
    class: 'goodwork'
  },

  {
    name: 'Lugang Cafe',
    src: lugang,
    class: 'lugang'
  },

  {
    name: 'Wildflour',
    src: wildflour,
    class: 'wildflour'
  }
];

const PartnersSection = () => {
  const listPartners = Partners.map((item, index) => (
    <li key={`partner-item-${index + 1}`}>
      <img alt={item.name} src={item.src} className={item.class} />
    </li>
  ));

  return (
    <PageSection className="section-partners gradient">
      <div className="container">
        <div className="columns">
          <div className="column wrapper">
            <h2 className="text-2xl-is-mobile text-3xl-is-tablet text-ultra text-gr800">
              Providing modern payments for forward thinking businesses
            </h2>
            <p className="text-lg-is-mobile text-xl-from-tablet text-gr800">
              PayMongo powers growth of the next-generation of innovative companies in the
              Philippines.
            </p>
            <ul className="partners-and-merchants">{listPartners}</ul>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default PartnersSection;
