import React from 'react';
import { string } from 'prop-types';

const BusinessPricingItem = props => {
  const { alt, className, description, size, src, textSize } = props;

  return (
    <div className={`business-item ${size} ${className}`}>
      <div className="image-wrapper">
        <img alt={alt} className={`image ${size}`} src={src} />
      </div>
      <p className={`text-gr800 ${textSize}`}>{description}</p>
    </div>
  );
};

BusinessPricingItem.propTypes = {
  alt: string,
  className: string,
  description: string.isRequired,
  size: string,
  src: string.isRequired,
  textSize: string
};

BusinessPricingItem.defaultProps = {
  alt: '',
  className: '',
  size: 'sm',
  textSize: 'sm'
};

export default BusinessPricingItem;
