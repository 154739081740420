import React from 'react';

import jao from '@images/testimonial-wildflour-jao-gamboa.jpg';
import kyra from '@images/testimonial-calli-kyra-co.jpg';

import './TestimonialsSection.scss';

import { PageSection, Button } from '@components';

import TestimonialListItem from './components/testimonial-list-item/TestimonialListItem';

const data = [
  {
    image: jao,
    user: 'Jao Gamboa',
    company: 'Wild Flour Bakery + Cafe',
    position: 'Director for Corporate Planning & Finance',
    message:
      '"By streamlining multiple methods of payment online, PayMongo allowed us to branch out from our core dine-in business to our first venture into the world of e-commerce with the launch of Wildflour To-Go."'
  },
  {
    image: kyra,
    user: 'Kyra Co',
    company: 'Calli Collective',
    position: 'Co-Founder',
    message:
      '"PayMongo has helped me accept credit card payments, which allowed some of my customers to pay instantly after ordering. This helped with our cashflow a lot, and it helped us fulfill orders faster."'
  }
];

const TestimonialsSection = () => {
  const testimonialItem = data.map((testimonial, index) => (
    <TestimonialListItem
      key={`testimonial-item-${index + 1}`}
      image={testimonial.image}
      user={testimonial.user}
      position={testimonial.position}
      company={testimonial.company}
      message={testimonial.message}
    />
  ));

  return (
    <PageSection className="section-testimonials">
      <div className="container">
        <div className="testimonial-content">
          <h3 className="text-xl text-sh600">What our customers are saying</h3>
          <ul className="columns is-centered">{testimonialItem}</ul>
          <Button
            className="button-cta display-block centered"
            buttonLabel="Get started for free"
            destination={process.env.GATSBY_LINK_TO_SIGNUP}
          />
        </div>
      </div>
    </PageSection>
  );
};

export default TestimonialsSection;
