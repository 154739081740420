import React from 'react';
import { node, string } from 'prop-types';

import '@style/base.scss';
import '@style/grid.scss';
import './SellingPointListItem.scss';

const SellingPointListItem = ({image, heading, subtext}) => (
  <li className="selling-point-list-item">
    <div className="icon-container">
      <img alt="placeholder" src={image} />
    </div>
    <div className="description">
      <h3 className="text-md text-medium text-gr700 text-left mb-xs">{heading}</h3>
      <p className="text-sm text-sh700">{subtext}</p>
    </div>
  </li>
);

SellingPointListItem.propTypes = {
  image: node.isRequired,
  heading: string.isRequired,
  subtext: string.isRequired
};

export default SellingPointListItem;
